import React, {useState,useEffect} from "react";
import Login from './Login';
import Hero from './Hero';
import './App.css';
import {getAuth,signInWithEmailAndPassword,createUserWithEmailAndPassword} from "firebase/auth"
import app from "./firebase" 
import {db} from './firebase';
import {ref,onValue} from 'firebase/database';



function App()  {
  const [user,setUser] = useState('');
  const [email,setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError,setPasswordError] = useState('');
  const [hasAccount,setHasAccount]  = useState(false);


  const auth=getAuth(app);

  const clearInputs = () => {
    setEmail('');
    setPassword('');  
  };

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const handleLogin = () => {
    localStorage.setItem("email",email);
    clearErrors();
    signInWithEmailAndPassword(auth,email,password)
      .catch(err => {
        switch(err.code){
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
           setEmailError(err.message);
           break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;  
        }
      });
  };

  const handleSignup = () => {
    clearErrors();
    createUserWithEmailAndPassword(auth,email,password)
      .catch(err => {
        switch(err.code){
          case "auth/email-already-in-use":
          case "auth/invalid-email":
           setEmailError(err.message);
           break;
          case "auth/weak-password":
            setPasswordError(err.message);
            break;
        }
      });
  };

  const handleLogout = () => {
    auth.signOut();
  };

  const authListener = () => {
    auth.onAuthStateChanged((user) => {
      if(user){
        clearInputs();
        setUser(user);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  },[]);

  return(
    <div className='App'>
      {user ? (
        <Hero handleLogout={handleLogout} />
      ): (
      <Login 
        email = {email}
        setEmail = {setEmail}
        password = {password}
        setPassword = {setPassword}
        handleLogin = {handleLogin}
        handleSignup = {handleSignup}
        hasAccount = {hasAccount}
        setHasAccount = {setHasAccount}
        emailError = {emailError}
        passwordError = {passwordError}
      />
      )}
    </div>
  );
};

export default App;
